<template>
	<div class="announce">
		<!-- 头部 -->
		<app-header @getHeight="getHeight" :phone="phone" />

		<div class="max_width min_width" :class="{'min_height': empty}">
			<div class="flex align_center padtb_20" :style="{'margin-top': offsetHeight + 'px'}">
				<div class="f838 size_14">当前位置：</div>
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item class="d8f">通知公告</el-breadcrumb-item>
				</el-breadcrumb>
			</div>

			<!-- 标签 -->
			<div class="bgfff relative" style="padding: 30px 30px 50px 30px;">
				<div>
					<div class="flex align_start">
						<div class="fnf size_15 weight marr_30 text_nowrap">栏目类别：</div>
						<div class="text_line1" style="width: calc(100% - 75px);" :class="{'text_wrap': !flag}">
							<span class="fnf weight size_15 marr_30 announce_font" :class="{'announce_tag': value1 == ''}"
								@click="setTag('value1')">全部</span>
							<span v-for="item in first" class="fnf size_15 weight marr_30 announce_font"
								:class="{'announce_tag': value1 == item.id}"
								@click="setTag('value1', item.id)">{{item.name}}</span>
						</div>
					</div>

					<!-- <div class="flex align_start">
						<div class="fnf size_15 weight announce_mar text_nowrap">所属区域：</div>
						<div class="text_line1" style="width: calc(100% - 75px);" :class="{'text_wrap': !flag}">
							<span class="announce_font fnf weight size_15 announce_mar" :class="{'announce_tag': value2 == ''}"
								@click="setTag('value2')">全部</span>
							<span v-for="item in second"
								class="fnf size_15 weight announce_mar text_nowrap announce_font"
								:class="{'announce_tag': value2 == item.id}"
								@click="setTag('value2', item.id)">{{item.name}}</span>
						</div>
					</div> -->

					<div class="flex align_center mart_20">
						<div class="fnf size_15 weight text_nowrap">关键词搜索：</div>
						<div class="width_50 announce_search">
							<el-input placeholder="请输入关键词" v-model="keyword" 
							@keyup.enter.native="searchData()"
							>
								 <i slot="suffix" class="el-icon-search" @click="searchData"></i>
							</el-input>
						</div>
					</div>

					<i class="el-icon-caret-bottom announce_icon" v-if="flag" @click="flag = !flag"></i>
					<i class="el-icon-caret-top announce_icon" v-else @click="flag = !flag"></i>
				</div>
			</div>

			<!-- 列表 -->
			<section class="martb_30">
				<div>
					<div class="flex align_start flex_wrap">
						<el-card class="box-card mart_20 announce_calc_card" shadow="hover" v-for="item in listsItem" @click.native='toannounceDetails(item.id)'>
							<div slot="header" class="clearfix announce_card_picBox">
								<img :src="item.img" class="announce_card_pic" />
							</div>
							<div class="item">
								<div class="ttt size_15 weight text_line1 font cursor" :title="item.title">
									{{item.title}}
								</div>
								<div class="flex align_center jus_content mart_10">
									<div class="flex align_center cursor eze time_font">
										<i class="el-icon-time icon_size"></i>
										<div class="size_14">{{item.create_time}}</div>
									</div>
					
									<div class="flex align_center cursor time_font">
										<i class="el-icon-user icon_size"></i>
										<div class="size_14">{{item.click_num}}</div>
									</div>
								</div>
							</div>
						</el-card>
					</div>
					
					<!-- 分页 -->
					<div class="width flex align_center jus_center padtb_30 relative">
						<app-mypaginations :pagSize='paginations' :allData='announceList' @setHandleCurrent='handleCurrent'
							@setHandleSize='handleSize' @setPage='setPage' @setSize='setSize' />
					</div>
				</div>
				
				<el-empty description="暂无数据" v-if='empty' :image-size="250"></el-empty>
			</section>
		</div>

		<!-- 尾部 -->
		<app-footer @phone="getPhone" />
	</div>
</template>

<script>
	import Header from '@/components/Header/header.vue'
	import Footer from '@/components/Footer/footer.vue'
	import MyPaginations from '@/components/MyPaginations/mypaginations.vue'

	export default {
		data() {
			return {
				// 分页
				paginations: {
					page_index: 1, // 当前位于哪页
					total: 10, // 总数
					page_size: 10, // 1页显示多少条
					page_sizes: [10, 20, 50, 90], //每页显示多少条
					layout: "total, sizes, prev, pager, next, jumper" // 翻页属性
				},
				listsItem: [],
				keyword: '',
				flag: true,
				// 栏目类别 id
				value1: '',
				// 所属区域 id
				value2: '',
				// 栏目类别 列
				first: [],
				// 所属区域 列
				second: [],
				announceList: [],
				// 数据总条数
				count: 0,
				// 是否数据为空
				empty: false,
				offsetHeight: 0,
				phone: ''
			}
		},
		components: {
			'app-header': Header,
			'app-footer': Footer,
			'app-mypaginations': MyPaginations
		},
		methods: {
			getPhone(phone) {
				this.phone = phone
			},
			// 获取头部高度
			getHeight(data) {
				this.offsetHeight = data
			},
			// 设置按钮 分类搜索
			setTag(name, id = '') {
				this[name] = id
				this.announceList = []
				
				this.getAnnouncePort()
			},
			setPage(data) {
				this.paginations.page_index = data
				
				this.getAnnouncePort()
			},
			setSize(data) {
				this.paginations.page_index = 1
				this.paginations.page_size = data
				
				this.getAnnouncePort()
			},
			handleCurrent(item) {
				this.listsItem = item
			},
			handleSize(item) {
				this.listsItem = item
			},
			//重置分页
			resetPaginations() {
				// 总页数
				this.paginations.total = this.count
				// 设置默认分页数据
				this.listsItem = this.announceList.filter((item, index) => {
					return index < this.paginations.page_size
				})
			},
			searchData() {
				this.paginations.page_index = 1
				this.announceList = []
				
				this.getAnnouncePort()
			},
			// 通知公告
			getAnnouncePort() {
				let data = {
					type: 2,
					page: this.paginations.page_index,
					limit: this.paginations.page_size,
					keyword: this.keyword,
					one: this.value1
					// two: this.value2
				}
				
				this.$get('/news_list', data).then(res => {
					this.announceList = res.result ? res.result : []
					this.count = res.count
					
					if(this.paginations.page_index == 1 && this.announceList.length == 0) {
						this.empty = true
					}else {
						this.empty = false
					}
					
					this.resetPaginations()
				})
			},
			// 栏目类别
			getFcatePort() {
				let data = { type: 2}
				
				this.$get('/category_list', data).then(res => {
					this.first = res.result
				})
			},
			// 所属区域
			getScatePort() {
				let data = { type: 3}
				
				this.$get('/category_list', data).then(res => {
					this.second = res.result
				})
			},
			// 跳转热点动态详情
			toannounceDetails(id) {
				this.$router.push({path: '/announcedetails', query: {ids: id}})
			}
		},
		mounted() {
			this.getFcatePort()
			this.getScatePort()
			this.getAnnouncePort()
		}
	}
</script>

<style scoped>
	@import url("./announcements.css");
</style>
